import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const ErrorContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 500px;
`
const ErrorLink = styled.a`
  color: #ededed;
  background-color: black;
  padding: .75rem 1.25rem;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.25rem;
  transition: background-color 0.5s ease;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;

  &:hover {
    background-color: #ededed;
    border: 1px solid black;
    color: black;
    transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <ErrorContainer>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <ErrorLink href="/">Head Home</ErrorLink>
    </ErrorContainer>
  </Layout>
)

export default NotFoundPage
